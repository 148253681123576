<template>
    <!-- 用户基本信息 -->
    <div id="userInfo" class="userInfo">
         <div class="user-content">
            <div class="box">
                <el-form label-width="100px">
                    <el-form-item label="ID：">
                        <span class="text">{{info.user_id}}</span>
                    </el-form-item>
                    <el-form-item label="微信号：">
                        <span class="text">{{info.open_id}}</span>
                    </el-form-item>
                    <el-form-item label="昵称：">
                        <span class="text">{{info.name}}</span>
                    </el-form-item>
                    <el-form-item label="注册时间：">
                        <span class="text">{{info.addtime}}</span>
                    </el-form-item>
                    <el-form-item label="绑定时间：">
                        <span class="text">{{info.addtime}}</span>
                    </el-form-item>
                </el-form>
            </div>
            <div class="mt-20">
                <el-form label-width="100px">
                    <el-form-item label="交易记录：">
                        <div class="tabless">
                            <el-table
                                :data="info.order"
                                :header-row-class-name="'table_header'"
                                :empty-text="tableTip"
                                :height="400"
                                v-loading="loading"
                            >>
                                <el-table-column prop="order_name" label="订单名称"></el-table-column>
                                <el-table-column prop="trader" label="交易对象"></el-table-column>
                                <el-table-column prop="order_amount" label="交易金额"></el-table-column>
                                <el-table-column prop="order_status" label="订单状态" ></el-table-column>
                                <el-table-column prop="trader_status" label="交易状态"></el-table-column>
                                <el-table-column prop="addtime" label="交易时间"></el-table-column>
                            </el-table>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { ShowUserBasicInfo  } from "../../api/api";
import { constants } from 'crypto';
export default {
    components:{
    },
    data() {
        return {
            id: this.$route.query.id,
            info:{},
            loading:false,
            tableTip: "系统正在检索中…",
        };
    },
    mounted() {
        this.getUserInfo();
    },
    methods:{
        getUserInfo (){
            this.loading = true;
            this.$axios.post(ShowUserBasicInfo,{'user_id':this.id}).then(res => {
                this.loading = false;
                if(res.data.code == 1){
                    this.info = res.data.data;
                    if(this.info.order.length == 0){
                        this.tableTip = "暂无搜索结果";
                    };
                }else{
                    this.info = {};
                    this.tableTip = "暂无搜索结果";
                    this.$message.error(res.data.msg);
                };
            }, err => {
                this.loading = false;
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.el-form-item{
    margin-bottom: 0px;
}
.tabless .el-table__header-wrapper th, td{
    padding: 0px;
}
</style>
